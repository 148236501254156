<template>
  <QuillEditor
    ref="myQuillEditor"
    v-model="item"
    :options="editorOptions"
    @input="$emit('input', item)"
  />
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    QuillEditor: quillEditor,
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      item: this.value,
      editorOptions: {
        placeholder: "Enter your content here",
      },
    };
  },
  watch: {
    value() {
      this.item = this.value;
    },
  },
};
</script>

<style>
  .ql-editor {
    background-color: white;
  }
</style>
